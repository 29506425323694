import {Grid,Container,Box,Paper, Typography} from '@mui/material';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function About() {
    return (
        <div id="contact" className='contact'>
        <Typography component="h2" variant="h3" textAlign="center">Contact us</Typography>
        <Grid container padding="2%" spacing={2}  display="flex"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        >
  <Grid item xs={12} md={6} >
  <Paper elevation={3}  sx={{minHeight:"50vh" , display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}}>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PhoneInTalkIcon />
          </Avatar>
        </ListItemAvatar>
        <Typography>
07404795000</Typography>
        
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <MarkEmailReadIcon />
          </Avatar>
        </ListItemAvatar>
        <Typography>Greetcars786@gmail.com</Typography>
        
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <LocationOnIcon />
          </Avatar>
        </ListItemAvatar>
        <Typography>120 Manor Farm, Birmingham, West midlands, B11 2HX</Typography>
      </ListItem>
    </List>
  </Paper>
  </Grid>
  <Grid item xs={12} md={6}>
  <Paper elevation={3} sx={{minHeight:"50vh",   display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}}>
        <div>
      <h1>Our Location</h1>
      <iframe
      
        style={{ border: 0 }}
        loading="lazy"
        allowfullscreen
        src="https://www.google.com/maps/embed/v1/place?q=place_id:EjIxMjAgTWFub3IgRmFybSBSZCwgVHlzZWxleSwgQmlybWluZ2hhbSBCMTEgMkhYLCBVSyIwEi4KFAoSCY-0qADAu3BIEbhNbCsrnSqsEHgqFAoSCRsbL4e_u3BIEc-W9mudYUaX&key=AIzaSyA6iki52IqkWXS-kC0Olcn0xMU0ewspJH4"
      />
    </div>
  </Paper>
  </Grid>
  
</Grid>
</div>

    )
}