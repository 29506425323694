import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Greet from '../images/greet.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MainBody from './mainBody';
import Services from './services';
import Links from './links';  
import Contact from './contact';
import Footer from './footer';
import Link from "@mui/material/Link";


const theme = createTheme();

  
  const ITEM_HEIGHT = 48;

export default function Album() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar>
      
        <Grid container spacing={2} p={2}>
 
  <Grid xs={4}>
    <div ><img alt="our logo" src={Greet} /></div>
  </Grid>
  <Grid  className='menuItems' xs={8}  >
   
   <Box sx={{ display: {xs: 'none', md: 'flex'}, alignItems: 'center', textAlign: 'center'}} >
   <Link href="#home" underline="none" sx={{color:'white',minWidth: 100}}>Home</Link>
      <Link href="#services" underline="none" sx={{color:'white',minWidth: 100}}>Services</Link>
      <Link href="#links" underline="none" sx={{color:'white',minWidth: 100}}>Links</Link>

      <Link href="#contact" underline="none" sx={{color:'white',minWidth: 100}}>Contact Us</Link>
      <Link href="#about" underline="none" sx={{color:'white',minWidth: 100}}>About Us</Link>
       
    </Box>
    <Box sx={{display:{xs:'block',md:'none'}}}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
       
      >
        <MoreVertIcon sx={{ color: 'white' }} fontSize="large" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        
        
          
          <MenuItem  onClick={handleClose}>
          <Link href="#home" underline="none" sx={{minWidth: 100}}>Home</Link>
          </MenuItem>
          <MenuItem  onClick={handleClose}>
          <Link href="#services" underline="none" sx={{minWidth: 100}}>Services</Link>
          </MenuItem>
          <MenuItem  onClick={handleClose}>
          <Link href="#links" underline="none" sx={{minWidth: 100}}>Links</Link>
          </MenuItem>
          <MenuItem  onClick={handleClose}>
          <Link href="#contact" underline="none" sx={{minWidth: 100}}>Contact Us</Link>
          </MenuItem>
         
          <MenuItem  onClick={handleClose}>
          <Link href="#about" underline="none" sx={{minWidth: 100}}>About Us</Link>
          </MenuItem>
       

       
      </Menu>
    </Box>
   
  </Grid>
</Grid>
            
            
     
      </AppBar>
      <main>
        <MainBody />
        <Services />
        <Links />
        <Contact />
        <Footer />
      </main>
  
    
    </ThemeProvider>
  );
}