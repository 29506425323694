import {Container, Box,Typography,Grid} from '@mui/material';
import bodyImg from '../images/body.jpg';

export default function MainBody(){
    return (
        <div className='mainImage' id="home">
          <Grid container spacing={2}>
  <Grid item xs={1} md={6}>
   {/* <div>I'm</div> */}
  </Grid>
  <Grid item xs={10} md={6}>
    <Typography  sx={{
    fontSize: {
      lg: 55,
      md: 40,
      sm: 30,
      xs: 30
    }
  }}>Gree Cars Ltd. Aims to help you out Buying or Selling your car Quick!</Typography>
  </Grid>
  <Grid item xs={1} >
    {/* <div>Nope</div> */}
  </Grid>
  
</Grid>
       
           
      </div>
    )
}