import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Links() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="links" className='links'>
        <Box
      sx={{
        height: 100,
        backgroundColor: 'primary.dark', display:'flex', alignItems: 'center' , textAlign: 'center',justifyContent:'center'}}
       
    
    >
        <Typography component="h2" variant="h3"  color="common.white" sx={{ fontSize: {
      lg: 65,
      md: 45,
      sm: 30,
      xs: 30
    }}} >
  You can see our Cars on the links below.
</Typography>;

    </Box>
    <Container maxWidth="md" sx={{paddingTop:'4%',paddingBottom:'4%'}} >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Check us on Ebay</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link href="https://www.ebay.co.uk/usr/greetcars1234" target="_blank">
          <Typography>
            Click here so you can see our Cars on Ebay.
          </Typography>
          </Link>
          
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Check us on Gumtree</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Link href="https://www.gumtree.com/sellerads/1435835382?page=1" target="_blank">
          <Typography>
            Click here so you can see our Cars on Gumtree.
          </Typography>
          </Link>
        </AccordionDetails>
      </Accordion>
      
      </Container>
    </div>
  );
}