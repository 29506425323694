import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Container } from '@mui/system';

export default function About() {
  const theme = useTheme();

  return (
    <div className='about' >
    <Container maxWidth="md" id="about">
    <Card sx={{ display: 'flex',textAlign:'center' }}>
      
        <CardContent sx={{ flex: '1 0 auto' ,width:1}}>
          <Typography component="h2" variant="h3">
            About Us
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Greet Cars ltd. is in the car business from a long time. We have managed to buy and sale around 1500+ cars in the span of just few years. We deal in every type of car. Greet Cars limited aims to provide a hassle free easy process service for it's clients all across the region.
          </Typography>
        </CardContent>
       
    
      
    </Card>
    </Container>
    </div>
  );
}