import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid  from '@mui/material/Grid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SellIcon from '@mui/icons-material/Sell';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import Box from '@mui/material/Box'

export default function Services() {
    return (
        <div className='services' id="services" >
          <Typography className='service-title' component="h2" variant="h3" textAlign="center">Our Services</Typography>
            <Box
            conatiner
  sx={{
    
    display: {  xs: 'block',sm:'block', md: 'grid',lg:'grid'}
,
    gridTemplateColumns: 'repeat(3, 1fr)' 
  }}
>
      
  <Card sx={{ maxWidth: 345 ,m: 2 }}>
      <CardActionArea>
      <div className='icon-position'>
        <ShoppingCartIcon />
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Buying
          </Typography>
          <Typography variant="body2" color="text.secondary">
            If you want to sell your cars, then we are here for you.The good News is we Buy cars with Cash.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
 
 
  <Card sx={{ maxWidth: 345 ,m: 2 }}>
      <CardActionArea>
      <div className='icon-position'>
        <SellIcon />
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">

            Selling
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We sell cars that are trusted and properly serviced.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
 
  <Card sx={{ maxWidth: 345 ,m: 2 }}>
      <CardActionArea>
        <div className='icon-position'>
        <CurrencyPoundIcon />
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Financing
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Greet Cars ltd is offering finance options for your dream car. Just contact us for further info.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
 </Box>
        
    </div>
    )
}